<template>
  <div class="sb_app">
    <sb-menu-main
      class="sb_app_main-menu"
      :environments="environments"
      :user="me && `${me.firstName} ${me.prefix || ''} ${me.lastName}`"
      :account-or-role="accountOrRole()"
      :routes="menuRoutes"
      :current-route="activeMainRoute"
      @context-change="onContextChange"
      @logout="logout"
      @switch-user="switchUser"
      @other-account="addAnotherAccount"
    />
    <div class="sb_app_wrapper">
      <sb-menu-side
        v-if="subRoutes.length && !$route.meta.hideFromSideMenu"
        :routes="subRoutes"
        :current-route="$route"
        class="sb_app_side-menu"
      />
      <div
        id="sb_app_content"
        class="sb_app_content"
        :class="{ 's_full-width': subRoutes.length === 0 }"
      >
        <!-- <SbBreadcrumb :routes="routes" /> -->
        <router-view @refetch="refetch" />
      </div>
    </div>
  </div>
</template>

<script>
import SbMenuMain from '@/components/SbMenuMain';
import SbMenuSide from '@/components/SbMenuSide';
import { routes } from '@/router/index';
import { onLogout, onSwitchUser, onAddAnotherAccount } from '@/vue-apollo';
// import SbBreadcrumb from '@/components/SbBreadcrumb.vue';

import UserEnvironmentOrganisations from '@/graphql/queries/UserEnvironmentOrganisations.gql';
import Me from '@/graphql/queries/Me.gql';
import { getCurrentUser } from '@/lib/user-management';

export default {
  name: 'UserTypeEnvironment',
  components: {
    // SbBreadcrumb,
    SbMenuMain,
    SbMenuSide,
  },
  data() {
    return {
      adminSchools: null,
    };
  },
  computed: {
    // ROUTES
    menuRoutes() {
      const parentRoute = routes.find(
        (route) => route.path === this.$route.matched[0].path,
      );
      return this.getMenuRoutes(parentRoute);
    },
    activeMainRoute() {
      const activeMainRoute = this.menuRoutes.find((menuRoute) =>
        this.$route.name.includes(menuRoute.name),
      );
      return activeMainRoute;
    },
    subRoutes() {
      const currentRoute = this.menuRoutes.find(
        (menuRoute) => menuRoute.name === this.activeMainRoute.name,
      );
      if (
        !currentRoute ||
        !currentRoute.subRoutes ||
        !currentRoute.subRoutes.length
      ) {
        return [];
      }
      return currentRoute.subRoutes;
    },
    role() {
      return this.$user.role;
    },
    allEnvironments() {
      return this.organisations;
    },
    environments() {
      // if (this.$user.role === 'ADMIN') {
      return {
        schools:
          this.organisations &&
          this.organisations.filter(
            (organisation) => organisation.organisationType === 'SCHOOL',
          ),
        schoolDistricts:
          this.organisations &&
          this.organisations.filter(
            (organisation) =>
              organisation.organisationType === 'SCHOOL_DISTRICT',
          ),
      };
      // }
      // return {
      //   schools: this.me.schools,
      //   schoolDistricts: this.me.schoolDistricts
      // };
    },
  },
  apollo: {
    organisations: {
      skip() {
        return this.$user.role !== 'ADMIN';
      },
      query: UserEnvironmentOrganisations,
      variables: {
        first: 1000,
      },
      update(data) {
        return data.organisations.edges.map((edge) => edge.node);
      },
    },
    me: {
      query: Me,
    },
  },
  methods: {
    refetch(e) {
      this.$apollo.queries.organisations.refetch();
    },
    // ROUTES
    shouldShowRoute(route) {
      if (!route.meta) return true;
      if (route.meta.hideFromMenu) return false;
      if (route.meta.showFor) {
        return route.meta.showFor.some((condition) => {
          if (!condition.context) return condition.role === this.$user.role;
          if (!condition.role) {
            return condition.context === this.$user.context.resourceType;
          }
          return (
            condition.role === this.$user.role &&
            condition.context === this.$user.context.resourceType
          );
        });
      }
      if (route.meta.hideFor) {
        return !route.meta.hideFor.some((condition) => {
          if (!condition.context) return condition.role === this.$user.role;
          if (!condition.role) {
            return condition.context === this.$user.context.resourceType;
          }
          return (
            condition.role === this.$user.role &&
            condition.context === this.$user.context.resourceType
          );
        });
      }
      return true;
    },
    getMenuRoutes(parentRoute) {
      if (!parentRoute || !parentRoute.children) return [];
      const mainRoutes = parentRoute.children.filter(
        (route) => !route.path.includes('/'),
      );
      return mainRoutes.map((route) => {
        const subRoutes = parentRoute.children.filter(
          (subRoute) =>
            subRoute.path !== route.path &&
            subRoute.path.includes(route.path) &&
            this.shouldShowRoute(subRoute),
        );
        return {
          ...route,
          subRoutes,
        };
      });
    },
    // USER
    accountOrRole() {
      if (this.$user.context.isAdmin) return 'Admin';
      const environment =
        this.allEnvironments &&
        this.allEnvironments.length &&
        this.allEnvironments.find(
          (_environment) => _environment.id === this.$user.resourceId,
        );

      const roleMap = {
        ADMIN: 'Admin',
        COACH: 'Begeleider',
        MANAGER: 'Beheerder',
        SCHOOL_DISTRICT_MANAGER: 'Schooldistrictbeheerder',
      };
      return `${roleMap[this.role]} - ${environment ? environment.name : ''}`;
    },

    async onContextChange(event) {
      if (!event.length) return;
      const resource = event[event.length - 1];
      if (['Scholen', 'Schooldistricten'].includes(resource)) {
        this.$Modal.info({
          title: 'Info',
          content: `Er zijn nog geen ${resource}`,
        });
        return;
      }
      const resourceId = resource.split('-')[1];
      const resourceType = resource.split('-')[0];

      if (resourceId === this.$route.params.resourceId) return;
      this.$router.push({
        name: 'Resource',
        params: {
          role: this.$user.role.toLowerCase(),
          resourceType,
          resourceId:
            resourceId === 'admin' && this.$user.role === 'ADMIN'
              ? 'default'
              : resourceId,
        },
      });
    },

    async logout() {
      await onLogout(this.$apollo.provider.defaultClient);
      location.reload();
    },

    /** Switch to other logged in account */
    async switchUser(username) {
      console.log(username);
      const currentUser = getCurrentUser();
      if (currentUser?.username === username) {
        console.log('already logged in as this user');
        return;
      }

      await onSwitchUser(this.$apollo.provider.defaultClient, username);
      location.reload();
    },

    /** Go to login to add another account */
    async addAnotherAccount() {
      await onAddAnotherAccount(this.$apollo.provider.defaultClient);
      location.reload();
    },
  },
};
</script>

<style lang="scss">
.sb_app {
  &_main-menu {
    background: $brand-white;
  }

  &_wrapper {
    display: flex;
    min-height: calc(100vh - 110px);
  }

  &_side-menu {
    flex: 0 0 230px;

    @media screen and (max-width: 1059px) {
      display: none;
    }
  }

  &_content {
    width: 100%;
    min-width: 800px; // Disabled because it interferes with mobile mode
    max-width: calc(100vw);
    padding: 10px 40px;

    // @media screen and (min-width: 1060px) {
    //   max-width: calc(100vw - 245px);
    // }

    &.s_full-width {
      max-width: calc(100vw);
    }

    @media screen and (min-width: 1200px) {
      padding: 10px 80px;
    }
  }
}
</style>
